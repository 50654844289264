<template>
  <footer class="py-6 text-center footer">
    <div class="px-4 pb-4 flex flex-justify-center gap-3 items-center">
        <div>Follow us:</div>
        <a href="https://open.spotify.com/artist/66hvouo3QBf31PuetihmSQ" target="_blank" title="Spotify">Spotify</a>
        <a href="https://www.youtube.com/@DenisKenzoRecordings" target="_blank" title="Youtube">YouTube</a>
        <a href="https://www.facebook.com/DenisKenzoRecordings" target="_blank" title="Facebook">Facebook</a>
        <a href="https://www.instagram.com/deniskenzorecordings" target="_blank" title="Instagram">Instagram</a>
        <a href="https://vk.com/deniskenzomusic" target="_blank" title="VK">VK</a>
    </div>
    <div>
      © Denis Kenzo Recordings | All rights reserved
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  font-size: 11px;

  a {
    color: var(--color-accent-lighter);
  }
}
</style>
