<script setup lang="ts">
import dkrLogoDark from '@@/assets/img/dkr-logo-dark-300.png'

const dkrLogoDarkUrl = computed(() => `https://dkrecs.com${dkrLogoDark}`)

useHead({
  title: 'Error 404 - Page Not Found | Denis Kenzo Recordings',
  meta: [
    { name: 'description', content: 'This page has been deleted or changed' },
    // Open Graph
    { property: 'og:title', content: 'Error 404 - Page Not Found | Denis Kenzo Recordings' },
    { property: 'og:description', content: 'This page has been deleted or changed' },
    { property: 'og:image', content: dkrLogoDarkUrl.value },
    { property: 'og:image:width', content: "300" },
    { property: 'og:image:height', content: "300" },
  ]
})
</script>

<template>
  <div class="wrapper">
    <PageHeader />
    <div class="mt-8 text-center">
      <h1>404</h1>
      <h2 class="mt-4 mb-8">PAGE NOT FOUND</h2>
      <div class="mt-8 mb-8 dkr-logo"/>
      <NuxtLink to="/" class="link">Go to home page</NuxtLink>
    </div>
    <PageFooter />
  </div>
</template>

<style lang="scss">
.wrapper {
  position: relative;
  max-width: 460px;
  min-width: 320px;
  margin: auto;
  padding: 16px;
}

.dkr-logo {
  height: 120px;
  background: url('/assets/img/dkr-logo-dark-300.png') center no-repeat;
  background-size: contain;
}

.link {
  color: var(--color-accent-lighter);
}
</style>