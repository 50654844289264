import { default as contactsuJvlwcRT0CMeta } from "F:/DEV/dkrecs/pages/contacts.vue?macro=true";
import { default as index5oUlBnZ4DvMeta } from "F:/DEV/dkrecs/pages/index.vue?macro=true";
import { default as _91id_93DqG5v9C9O7Meta } from "F:/DEV/dkrecs/pages/tracks/[id].vue?macro=true";
import { default as indexMO5MbDxlXQMeta } from "F:/DEV/dkrecs/pages/tracks/index.vue?macro=true";
import { default as _91id_93eziscORwqSMeta } from "F:/DEV/dkrecs/pages/videos/[id].vue?macro=true";
import { default as indexWoCIYQHu3eMeta } from "F:/DEV/dkrecs/pages/videos/index.vue?macro=true";
export default [
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("F:/DEV/dkrecs/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("F:/DEV/dkrecs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "tracks-id",
    path: "/tracks/:id()",
    component: () => import("F:/DEV/dkrecs/pages/tracks/[id].vue").then(m => m.default || m)
  },
  {
    name: "tracks",
    path: "/tracks",
    component: () => import("F:/DEV/dkrecs/pages/tracks/index.vue").then(m => m.default || m)
  },
  {
    name: "videos-id",
    path: "/videos/:id()",
    component: () => import("F:/DEV/dkrecs/pages/videos/[id].vue").then(m => m.default || m)
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("F:/DEV/dkrecs/pages/videos/index.vue").then(m => m.default || m)
  }
]